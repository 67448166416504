/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function($, Drupal, window, document, undefined) {


    //To understand behaviors, see https://drupal.org/node/756722#behaviors
    Drupal.behaviors.my_custom_behavior = {
        attach: function(context, settings) {
            //Show/Hide search form in header
            (function(){
                var $button = $('.menu--search'),
                    $search = $('.header .block--views--exp-sitewide-search-page'),
                    hidden = true;
                $button.unbind('click').on('click', function(e){
                    e.preventDefault();
                    if(!$("body").hasClass("page-search")) {
                        if ( hidden ) {
                          $search.addClass('shown').slideDown('1000', function(){
                            $("#edit-search-api-views-fulltext").focus();
                          });
                    } else {
                          $search.slideUp('1000', function(){
                            $search.removeClass('shown');
                          });
                    }
                    hidden = ! hidden;
                    }
                });
            })();

            $(".carousel").flexslider({
                animation: "fade",
                controlsContainer: ".block--homepage-hero-rotator .view-content .slides",
                slideshowSpeed: 8000,
                slideshow: false,
                prevText: "<span aria-hidden=\"true\"><</span><span class=\"element-invisible\">Previous</span>",
                nextText: "<span aria-hidden=\"true\">></span><span class=\"element-invisible\">Next</span>",
                start: function() { homepageHeroSetAriaHidden($('.carousel'))},
                after: function() { homepageHeroSetAriaHidden($('.carousel'))},
            });
        }
    };

    $(document).ready(function() {
        var currentPage = window.location.pathname, 
            $activePFM = $('.view--pfm-checklist-accordion').find('a'),
            $newHeader = $(".view-id-pfm_checklist_accordion.view-display-id-block_pfm_checklist > .view-content"),
            index; 
        $activePFM.each(function() {
            if($(this).attr('href') === currentPage) {
                index = $(this).parents('.ui-accordion-content').prev('h3').index();
            }
        });
        //Divide index to remove counting of divs when calculating new active h3
        $newHeader.accordion( "option", "active", index/2 );

        //http://alistapart.com/article/fontresizing
        function init()  {
          // Don't do this if you're on mobile.
          if ($('.block--your-yale-mobile-nav').css('display') != 'none') {
            return;
          }

           var iBase = TextResizeDetector.addEventListener(onFontResize,null);

          if(iBase>24) {
            var menuBackground = document.querySelectorAll(".menu--header-main-menu ul ul");
            var i;
            for (i = 0; i < menuBackground.length; i++) {
              menuBackground[i].style.zIndex="1"; }
            document.querySelector(".menu--header-main-menu ul .last > ul").style.right="initial";
          } else {
            var menuBackground = document.querySelectorAll(".menu--header-main-menu ul ul");
            var i;
            for (i = 0; i < menuBackground.length; i++) {
              menuBackground[i].style.zIndex="-1"; }
            document.querySelector(".menu--header-main-menu ul .last > ul").style.right="0";
          }
        }

        function onFontResize(e,args) {
          // Don't do this if you're on mobile.
          if ($('.block--your-yale-mobile-nav').css('display') != 'none') {
            return;
          }

          if(args[0].iSize>24) {
            var menuBackground = document.querySelectorAll(".menu--header-main-menu ul ul");
            var i;
            for (i = 0; i < menuBackground.length; i++) {
              menuBackground[i].style.zIndex="1"; }
            document.querySelector(".menu--header-main-menu ul .last > ul").style.right="initial";
          } else {
            var menuBackground = document.querySelectorAll(".menu--header-main-menu ul ul");
            var i;
            for (i = 0; i < menuBackground.length; i++) {
              menuBackground[i].style.zIndex="-1"; }
            document.querySelector(".menu--header-main-menu ul .last > ul").style.right="0";
          }
        }

        //id of element to check for and insert control
        TextResizeDetector.TARGET_ELEMENT_ID = 'block-menu-block-2';
        //function to call once TextResizeDetector has init'd
        TextResizeDetector.USER_INIT_FUNC = init;
    });

    /**
     * Columnizer calls were placed here as legacy code; Please add new calls
     * to Columnizer in the modules or features from which the markup comes.
     */
    $(function(){
        //Adding columnizer to PFM Checklist
        $('.view--pfm-checklist-matrix .grouped-view').addClass("dontsplit");
        $('.view--pfm-checklist-matrix .view-content').columnize({width:400, lastNeverTallest: true});        
        //Adding columnizer to Related Information block 
        $('.block--related-information-two-column .views-field-field-link-to-external-story').addClass("dontsplit");
        $('.block--related-information-two-column .view-content').columnize({ columns: 2 });
        //Adding Policies & Procedures TOC 
        $('.block--full-band-three-col ul li').addClass("dontsplit");
        $('.block--full-band-three-col ul').columnize({width:400, lastNeverTallest: true});
    });

    /**
     * Set screen reader visibility for active slides.
     */
    function homepageHeroSetAriaHidden(flexSliderObject) {
        var activeSlide = $(flexSliderObject).find('ul.slides > li.flex-active-slide'),
            inactiveSlides = $(flexSliderObject).find('ul.slides > li').not(activeSlide);

        $(activeSlide).attr('aria-hidden', 'FALSE');
        $(activeSlide).find('a').removeAttr('tabindex');

        $(inactiveSlides).attr('aria-hidden', 'TRUE');
        $(inactiveSlides).find('a').attr('tabindex', '-1');
    }

})(jQuery, Drupal, this, this.document);
