/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function($, Drupal, window, document, undefined) {

    // Run on window load instead of on DOM Ready in case images or other scripts affect element widths
    $(window).on('load', function() {
        // Check all tables. You may need to be more restrictive.
        $('table').each(function() {
            var element = $(this);
            // Create the wrapper element
            var scrollWrapper = $('<div />', {
                'class': 'widetable',
                'html': '<div />' // The inner div is needed for styling 
            }).insertBefore(element);
            // Store a reference to the wrapper element
            element.data('scrollWrapper', scrollWrapper);
            // Move the scrollable element inside the wrapper element
            element.appendTo(scrollWrapper.find('div'));
            // Check if the element is wider than its parent and thus needs to be scrollable
            if (element.outerWidth() > element.closest("#content").outerWidth()) {
                element.data('scrollWrapper').addClass('has-scroll');
            }
            // When the viewport size is changed, check again if the element needs to be scrollable
            $(window).on('resize orientationchange', function() {
                if (element.outerWidth() > element.closest("#content").outerWidth()) {
                    element.data('scrollWrapper').addClass('has-scroll');
                } else {
                    element.data('scrollWrapper').removeClass('has-scroll');
                }
            });
        });
    });

})(jQuery, Drupal, this, this.document);
