/**
 * @file
 * A JavaScript file for the theme main menu mobile view.
 *
 */

(function($, Drupal, window, document, undefined) {

    (function() {
        var $mainMenu = $('header .menu--header-main-menu'),
        isMobile,
        menuClosed = true;

        var toggleMenu = function(e) {
               var $header = $('.header'),
                $headerRegion = $('.header__region');
                $('.nav-toggle').toggleClass('active');
                
                if ( menuClosed ) {
                    if(!$('body').hasClass('page-search')) {
                        $('#block-views-exp-sitewide-search-page').slideUp(0).removeClass('accessibly-shown');
                    } 
                    
                    var menuEl = document.getElementById('block-menu-block-2'), 
                        mlmenu = new MLMenu(menuEl, {
                            breadcrumbsCtrl : true, 
                            initialBreadcrumb : 'All', 
                            backCtrl : false,  
                    });
                    //add main level link to submenu
                    $('header .menu .is-expanded > a').each(function() {
                        $(this).next('.menu').prepend('<li class="top__menu menu__item"></li>');
                        var subMenu = $(this).next('.menu').children('.top__menu');
                        $(this).clone().prependTo(subMenu);
                    });
                    updateMenuHeight();
                    $headerRegion.slideUp(0,function(){
                        $header.addClass('header-mobile');
                        $('#block-menu-block-2').addClass('menu--open');
                        $('#block-menu-block-8').attr('aria-hidden', 'false')
                        .find('a.menu__link').attr('tabIndex', '0');
                        $headerRegion.addClass('open-nav')
                            .slideDown('1000');
                        });

                    $('.nav-toggle').attr('aria-expanded', 'true');
                } else {
                    if(isMobile ) {
                        $headerRegion.slideUp('1000',function(){
                            $header.removeClass('header-mobile');
                            $mainMenu.children('.menu').first().removeClass('menu--open');
                            $('#block-menu-block-8').attr('aria-hidden', 'true')
                            .find('a.menu__link').attr('tabIndex', '-1');
                            $headerRegion.removeClass('open-nav')
                                .slideDown(0);

                        });
                    } else {
                        $headerRegion.slideUp(0,function(){
                        $header.removeClass('header-mobile');
                        $mainMenu.children('.menu').first().removeClass('menu--open');
                        $('#block-menu-block-8').attr('aria-hidden', 'true')
                        .find('a.menu__link').attr('tabIndex', '-1');
                        $headerRegion.removeClass('open-nav')
                            .slideDown(0);

                    });
                    }

                  $('.nav-toggle').attr('aria-expanded', 'false');
                    cleanUpMenu();
                }
    
                menuClosed = ! menuClosed; 
            }

        var cleanUpMenu = function() {
            $('header .is-expanded .top__menu').remove();
            $('header .menu__item').each(function(){
                var $this = $(this);
                if($this.hasClass('focus')){
                    $this.removeClass('focus');
                }
                if($this.hasClass('active')){
                    $this.removeClass('active');
                }
            });
            $('.menu__level').each(function() {
                var $this = $(this);
                if($this.hasClass('menu--current')){
                    $this.removeClass('menu--current');
                }
                if($this.hasClass('show-menu')){
                    $this.removeClass('show-menu');
                }
                if($this.attr('aria-hidden', 'true')) {
                    $this.attr('aria-hidden', 'false');
                } 
            });
            //clear properties from menu object
            mlmenu = {
                breadcrumbsCtrl : '',
                el : '',
                current : '',
                menus : '',
                menusArr : '',
                options : ''
            };
            $('header .menu__breadcrumbs').remove();
        };

        var updateMenuHeight = function() {
            var menuHeight = 0;
            if(isMobile && ($(this).hasClass('menu__link'))) {
                $(this).next('.menu').children().each(function(i){
                    menuHeight += $(this).outerHeight(true);
                });   
            } else {
                $('header .menu-name-main-menu > .menu').children().each(function(i){
                    menuHeight += $(this).outerHeight(true);
                });
            }
            $('header .menu-name-main-menu').outerHeight(menuHeight); 
        };

        var keyCodeMap = {
        48:"0", 49:"1", 50:"2", 51:"3", 52:"4", 53:"5", 54:"6", 55:"7", 56:"8", 57:"9", 59:";",
        65:"a", 66:"b", 67:"c", 68:"d", 69:"e", 70:"f", 71:"g", 72:"h", 73:"i", 74:"j", 75:"k", 76:"l",
        77:"m", 78:"n", 79:"o", 80:"p", 81:"q", 82:"r", 83:"s", 84:"t", 85:"u", 86:"v", 87:"w", 88:"x", 89:"y", 90:"z",
        96:"0", 97:"1", 98:"2", 99:"3", 100:"4", 101:"5", 102:"6", 103:"7", 104:"8", 105:"9"
        };

        //Remove mobile styles and undo new dom order
        var unMobilizeYourYale = function() {
            isMobile = false;
            if(!menuClosed) {
                toggleMenu();
            } else {
                cleanUpMenu();
            }
            $('#block-menu-block-8').detach().prependTo('.header__region');
            $('#block-your-yale-mobile-nav-your-yale-mobile-nav-buttons').detach().prependTo('.header__region');
            
            if(!$('body').hasClass('page-search')) {
                $('#block-views-exp-sitewide-search-page').detach().insertAfter('#block-menu-block-8');
            } 
            $('.header__region').css({
                'overflow':'visible',
                'display': 'block'
            });
            $('header .menu-name-main-menu').css('height', 'auto');
            
            
            
        };
        //Update classes, add interactions, and change dom order in mobile views
        var mobilizeYourYale = function() { 
            $('#block-menu-block-8').detach().appendTo('.header__region').attr('aria-hidden', 'true');
            $('#block-menu-block-8').find('a.menu__link').attr('tabIndex', '-1');

            $('#block-your-yale-mobile-nav-your-yale-mobile-nav-buttons').detach().insertAfter('.header__logo');
            if(!$('body').hasClass('page-search')) {
               $('#block-views-exp-sitewide-search-page').detach().insertAfter('#block-your-yale-mobile-nav-your-yale-mobile-nav-buttons');
            }
            $('.header__region').css({
                'overflow':'hidden',
                'display': 'block'
            });
            
            $('.nav-search').unbind('click').on('click', function(e) {
                // If menu is closed, show the search bar.
                if (menuClosed && !$('body').hasClass('page-search')) {
                    e.preventDefault();
                    if ($('#block-views-exp-sitewide-search-page').hasClass('shown')) {
                        $('#block-views-exp-sitewide-search-page').slideUp('1000', function() {
                            $('#block-views-exp-sitewide-search-page').removeClass('shown');
                        });
                    }
                    else {
                        $('#block-views-exp-sitewide-search-page').addClass('shown').slideDown('1000');
                    }
                } else {
                    e.preventDefault();
                    $("#edit-search-api-views-fulltext").focus();
                }
            });  
            
            $('.nav-toggle').unbind('click').on('click', toggleMenu);          
    
            isMobile = true;
            updateMenuHeight();
            $(document).on('click','header .menu__breadcrumbs a', updateMenuHeight);
            $('header .menu .is-expanded > a, header button.menu__back').unbind('click').on('click', updateMenuHeight);
        };
    
        var mobileSwitch = function() {
            var footerCheck = $('.region-footer .footer-menu__column').css('float') === 'none' ? true : false;
            if (footerCheck && !isMobile) {
                mobilizeYourYale();
            } else if (!footerCheck && isMobile) {
                unMobilizeYourYale();
            }
        };

        
    
        $(document).ready(function() {
            //Add classes needed for mobile
            $('header .menu-name-main-menu > .menu').addClass('menu__level');
            $('.menu--header-main-menu .menu li.is-expanded > a').each(function(i) {
                if (!$(this).hasClass('toggle-submenu')) {
                    $(this).attr('data-submenu','submenu-' + (i+1));
                    $(this).next('ul').attr('data-menu','submenu-' + (i+1));
                    $(this).next('ul').addClass('menu__level');
                }     
            });
            $('header .menu-name-main-menu > .menu').setup_navigation();
        });
    
        $(window).resize(mobileSwitch);
        $(document).ready(mobileSwitch);

        //Accessibility Fixes
        $.fn.setup_navigation = function(settings) {

            settings = jQuery.extend({
                menuHoverClass: 'show-menu',
                parentMenuHoverClass: 'focus'
            }, settings);
    
            // Add ARIA role to menubar and menu items
            $(this).attr('role', 'menubar').find('li').attr('role', 'menuitem');
    
            var top_level_links = $(this).find('> li');
    
            // Set tabIndex to -1 so that top_level_links can't receive focus until menu is open
            $(top_level_links).children('ul')
                .attr('data-test','true')
                .attr({ 'aria-hidden': 'true', 'role': 'menu' })
                .find('a')
                    .attr('tabIndex',-1);
            
            // Adding aria-haspopup for appropriate items
            $(top_level_links).each(function(){
                if($(this).children('ul').length > 0)
                    $(this).attr('aria-haspopup', 'true');
            });
            
            $(top_level_links).hover(function(){
                $(this).closest('ul') 
                    .attr('aria-hidden', 'false')
                    .find('.'+settings.menuHoverClass)
                        .attr('aria-hidden', 'true')
                        .removeClass(settings.menuHoverClass)
                        .find('a')
                            .attr('tabIndex',-1);
                $(this).children('ul')
                    .attr('aria-hidden', 'false')
                    .addClass(settings.menuHoverClass)
                    .find('a').attr('tabIndex',0);
            });
            $(top_level_links).children('a').focus(function(){
                $(this).closest('ul')
                    .attr('aria-hidden', 'false')
                    .find('.'+settings.menuHoverClass)
                        .attr('aria-hidden', 'true')
                        .removeClass(settings.menuHoverClass)
                        .find('a')
                            .attr('tabIndex',-1);
                $(this).parent('li').closest('ul')
                    .find('.'+settings.parentMenuHoverClass)
                        .removeClass(settings.parentMenuHoverClass);
                $(this).closest('li')
                        .addClass(settings.parentMenuHoverClass);
                $(this).next('ul')
                    .attr('aria-hidden', 'false')
                    .addClass(settings.menuHoverClass)
                    .find('a').attr('tabIndex',0);
            });
            
            // Bind arrow keys for navigation
            $(top_level_links).children('a').keydown(function(e){
                if(e.keyCode == 37) { //left arrow
                    e.preventDefault();
                    // This is the first item
                    if($(this).parent('li').prev('li').length == 0) {
                        $(this).parent('li').parent('ul').find('> li').last().find('a').first().focus();
                    } else {
                        $(this).parent('li').prev('li').find('a').first().focus();
                    }
                } else if(e.keyCode == 38) { //up arrow
                    e.preventDefault();
                    if($(this).parent('li').find('ul').length > 0) {
                        $(this).parent('li').find('ul')
                            .attr('aria-hidden', 'false')
                            .addClass(settings.menuHoverClass)
                            .find('a').attr('tabIndex',0)
                                .last().focus();
                    }
                } else if(e.keyCode == 39) { //right arrow
                    e.preventDefault();
                    // This is the last item
                    if($(this).parent('li').next('li').length == 0) {
                        $(this).parent('li').parent('ul').find('> li').first().find('a').first().focus();
                    } else {
                        $(this).parent('li').next('li').find('a').first().focus();
                    }
                } else if(e.keyCode == 40) { //down arrow
                    e.preventDefault();
                    if($(this).parent('li').find('ul').length > 0) {
                        $(this).parent('li').find('ul')
                            .attr('aria-hidden', 'false')
                            .addClass(settings.menuHoverClass)
                            .find('a').attr('tabIndex',0)
                                .first().focus();
                    }
                } else if(e.keyCode == 27) { //escape
                    e.preventDefault();
                    $('.'+settings.menuHoverClass)
                        .attr('aria-hidden', 'true')
                        .removeClass(settings.menuHoverClass)
                        .find('a')
                            .attr('tabIndex',-1);
                    $('.'+settings.parentMenuHoverClass )
                        .removeClass(settings.parentMenuHoverClass);
                } else {
                    $(this).find('ul[aria-hidden=false] a').each(function(){
                        if($(this).text().substring(0,1).toLowerCase() == keyCodeMap[e.keyCode]) {
                            $(this).focus();
                            return false;
                        }
                    });
                }
            });
            
            
            var links = $(top_level_links).find('> ul').find('a');
            $(links).unbind().keydown(function(e){
                if( e.keyCode == 38) { //up arrow
                    e.preventDefault();
                    // This is the first item
                    if($(this).parent('li').prev('li').length == 0) {
                        $(this).parents('ul').parents('li').find('a').first().focus();
                    } else {
                        $(this).parent('li').prev('li').find('a').first().focus();
                    }
                } else if(e.keyCode == 40) { //down arrow
                    e.preventDefault();
                    if($(this).parent('li').next('li').length == 0) {
                        $(this).parents('ul').parents('li').find('a').first().focus();
                    } else {
                        $(this).parent('li').next('li').children('a').focus();
                    }
                } else if(e.keyCode == 27 || e.keyCode == 37) { //escape & left arrow
                    e.preventDefault();
                    $(this)
                        .parents('ul').first()
                            .prev('a').focus()
                            .parents('ul').first().find('.'+settings.menuHoverClass)
                                .attr('aria-hidden', 'true')
                                .removeClass(settings.menuHoverClass)
                                .find('a')
                                    .attr('tabIndex',-1);
                } else if(e.keyCode == 32) { //space
                    e.preventDefault();
                    window.location = $(this).attr('href');
                } else {
                    var found = false;
                    $(this).parent('li').nextAll('li').find('a').each(function(){
                        if($(this).text().substring(0,1).toLowerCase() == keyCodeMap[e.keyCode]) {
                            $(this).focus();
                            found = true;
                            return false;
                        }
                    });
                    
                    if(!found) {
                        $(this).parent('li').prevAll('li').find('a').each(function(){
                            if($(this).text().substring(0,1).toLowerCase() == keyCodeMap[e.keyCode]) {
                                $(this).focus();
                                return false;
                            }
                        });
                    }
                }
            });
        
                
            // Hide menu if click or focus occurs outside of navigation
            $(this).find('a').last().keydown(function(e){ 
                if(e.keyCode == 9) {
                    // If the user tabs out of the navigation hide all menus
                    $('.'+settings.menuHoverClass)
                        .attr('aria-hidden', 'true')
                        .removeClass(settings.menuHoverClass)
                        .find('a')
                            .attr('tabIndex',-1);
                    $('.'+settings.parentMenuHoverClass)
                        .removeClass(settings.parentMenuHoverClass);
                }
            });
            $(document).click(function(){ 
                $('.'+settings.menuHoverClass)
                    .attr('aria-hidden', 'true')
                    .removeClass(settings.menuHoverClass)
                    .find('a')
                    .attr('tabIndex',-1); 
                $('.'+settings.parentMenuHoverClass)
                    .removeClass(settings.parentMenuHoverClass);
            });
            $(this).click(function(e){
                e.stopPropagation();
            });
        }


    })();

})(jQuery, Drupal, this, this.document);
