/**
 * File poached from Gesso Theme https://www.drupal.org/project/gesso
 * To understand the fix http://terrillthompson.com/blog/161
 */

(function ($) {

  // Fixes skiplink behavior for Opera and Webkit browsers.
  Drupal.behaviors.skiplinks = {
    attach: function (context) {
      var $context = $(context),
        $skiplink = $context.find('#skip-link a'),
        target = $skiplink.attr('href');

      // Set tabindex on the skiplink target so all browsers know they are
      // focusable and so Webkit browsers will focus() them.
      $(target).attr('tabindex', -1);

      // Set focus to skiplink target.
      $skiplink.click(function () {
        var clickAnchor = '#' + this.href.split('#')[1];
        $(clickAnchor).focus();
      });
    }
  };

})(jQuery);
